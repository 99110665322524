import { imageScaleToContainInMaxWidthAndMaxHeight } from "../helpers/image";
import { PastaItemDto } from "../models/pasta.dtos";
import { Transformation } from "../models/transformation.dtos";
import { chatGptService } from "../modules/chat-gpt/chat-gpt.service";

const imageToDiagramTransformation: Transformation = {
    name: "Image to Diagram",
    id: "image-to-diagram",
    canTransform: async (pastaItem: PastaItemDto) => {
        return pastaItem.source.items.some(i => i.type.indexOf('image') !== -1);
    },
    transform: async (pastaItem: PastaItemDto) => {

        const imageData = pastaItem.source.items.find(i => i.type.indexOf('image') !== -1);

        // resize the image data
        const resizedData = await imageScaleToContainInMaxWidthAndMaxHeight(imageData?.data, 400, 400);

        try {
            const result = await chatGptService.completePromptWithVision(
                `Your purpose in life is to convert hand drawn diagrams to a JSON representation. Input will be an image_url and output will be JSON that represents the diagram. Your response MUST be the raw JSON without formatting.
The Diagram JSON should have the following structure:

interface Diagram {
    nodes: Node[];
    edges: Edge[];
}

interface Node {
    id: string;
    label: string;
    shape: 'circle' | 'square' | 'cylinder' | 'diamond';
}

interface Edge {
    source: string;
    target: string;
    label?: string;
}`,
                resizedData
            );

            // log result to console
            console.log(result);

            // try parse result as JSON
            return JSON.stringify(JSON.parse(result), null, 2);
        } catch (error) {
            // log error to console
            console.log(error);
            throw new Error('Failed to convert image to diagram syntax');    
        }

    }
}

export interface Diagram {
    nodes: Node[];
    edges: Edge[];
}

interface Node {
    id: string;
    label: string;
    shape: 'circle' | 'square' | 'cylinder' | 'diamond';
}

interface Edge {
    source: string;
    target: string;
    label?: string;
}



export default imageToDiagramTransformation;

export interface MiroClipboard {
    isProtected: boolean;
    boardId: string;
    version: 2;
    host: 'miro.com';
    data: MiroData;
}

export interface MiroData {
    objects: MiroObject[];
    meta: {};
}

export interface MiroObject {
    id: number;
    type: 14;
    initialId: string;
    widgetData: MiroWidgetData;
}

type MiroWidgetData = MiroShapeWidgetData | MiroLineWidgetData | MiroStickerWidgetData;

export interface MiroShapeWidgetData {
    type: 'shape';
    json: MiroShapeJsonData;
}

export interface MiroShapeJsonData {
    size:             Size;
    _position:        Position;
    scale:            Scale;
    relativeScale:    number;
    rotation:         Rotation;
    relativeRotation: number;
    position:         Point;
    _parent:          null;
    text:             string;
    style:            string;
    shape:            string;
}

export interface MiroLineWidgetData {
    type: 'line';
    json: MiroLineJsonData;
}

export interface MiroLineJsonData {
    points:    [];
    primary:   WidgetReference;
    secondary: WidgetReference;
    _position: null;
    _parent:   null;
    style:     string;
    line:      Line;
}

export interface MiroStickerWidgetData {
    type: 'sticker';
    json: MiroStickerJsonData;
}

export interface MiroStickerJsonData {
    size:             Size;
    _position:        Position;
    scale:            Scale;
    relativeScale:    number;
    rotation:         Rotation;
    relativeRotation: number;
    position:         Point;
    _parent:          null;
    text:             string;
    style:            string;
}

// json widget dependencies

export interface Position {
    offsetPx: Point;
}

export interface Point {
    x: number;
    y: number;
}

export interface Rotation {
    rotation: number;
}

export interface Scale {
    scale: number;
}

export interface Size {
    width:  number;
    height: number;
}

export interface Line {
    captions: any[];
}

export interface WidgetReference {
    point:        Point;
    positionType: number;
    widgetIndex:  number;
}