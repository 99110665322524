import React, { useContext } from "react";
import { PastaItemDto } from "../../models/pasta.dtos";
import { Representation, representationCopyValueBase } from "../../models/representation.dto";
import { pastaItemExtendData, pastaItemGetExtendedData } from "../../helpers/pasta-item-data";
import { PastaRepositoryContext } from "../../context/PastaRepositoryContext";

const imageOcrRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Image OCR',
    id: 'image-ocr',
    priority: 50,
    canRender: async (pastaItem: PastaItemDto) => {

        const extendedData = pastaItemGetExtendedData(pastaItem, imageOcrRepresentation.id);

        if (extendedData || pastaItem.source.items.find(i => i.type.indexOf('image') !== -1)) {
            return true;
        }

        return false;
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {

        const [text, setText] = React.useState<string>('Loading...');
        const { updateItem } = useContext(PastaRepositoryContext)!;

        const cachedData = pastaItemGetExtendedData(pastaItem, imageOcrRepresentation.id);
        const data = pastaItem.source.items.find(i => i.type.indexOf('image') !== -1);

        React.useEffect(() => {

            if (cachedData) {
                setText(cachedData);
                return;
            }

            if (data) {
                extractText(data.data).then((text) => {

                    pastaItemExtendData(pastaItem, imageOcrRepresentation.id, text, updateItem);

                    setText(text);
                });
            }
        }, [data, cachedData, pastaItem, updateItem]);

        return (
            <div className="raw-representation">
                <pre className="p-4">{text}</pre>
            </div>
        )
    }
}

export default imageOcrRepresentation;

async function extractText(base64Image: string) {

    const blob = dataUriToBlob(base64Image);
    const data = await getArrayBufferFromBlog(blob);

    const formData = new FormData();

    formData.append('image', new Blob([data]));

    const url = process.env.REACT_APP_OCR_SERVICE_PATH;
    const options: RequestInit = {
        method: 'POST',
        body: formData
    };

    try {
        const result = await fetch(url!, options)
        const json = await result.json();
        return json.text;
    } catch (error) {
        console.log(error);
    }
}

async function getArrayBufferFromBlog(blob: Blob) {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function (event: any) {
            resolve(event.target.result as ArrayBuffer);
        };

        reader.readAsArrayBuffer(blob as Blob);
    });
}

function dataUriToBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    
    
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // return [ab];
    
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
}