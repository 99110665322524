import React, { ReactNode } from "react";
import { Representation } from "../models/representation.dto";
import rawRepresentation from "../components/representations/raw";
import prettyPrintJsonRepresentation from "../components/representations/pretty";
import imageRepresentation from "../components/representations/image";
import imageOcrRepresentation from "../components/representations/image-ocr";
import formattedRepresentation from "../components/representations/formatted";
import linkPreviewRepresentation from "../components/representations/link-preview";
import codeEditorRepresentation from "../components/representations/code-editor";
import blockEditorRepresentation from "../components/representations/block-editor";
import clipboardDataRepresentation from "../components/representations/clipboard-data";

interface RepresentationsContextProps {
  representations: Representation[];
}

export const RepresentationsContext = React.createContext<RepresentationsContextProps>({ representations: [] });

export const RepresentationsProvider = ({ children }: { children: ReactNode }) => {
  
  // Collect all representations in an array
  const representations: Representation[] = [
    rawRepresentation,
    prettyPrintJsonRepresentation,
    imageRepresentation,
    imageOcrRepresentation,
    formattedRepresentation,
    linkPreviewRepresentation,
    codeEditorRepresentation,
    blockEditorRepresentation,
    clipboardDataRepresentation,
    // miroManipulatorRepresentation,
  ].sort((a, b) => b.priority - a.priority);

  return (
    <RepresentationsContext.Provider value={{ representations }}>
      {children}
    </RepresentationsContext.Provider>
  );
};