import { PastaItemDto } from "../../models/pasta.dtos";
import { Representation, representationCopyValueBase } from "../../models/representation.dto";

const formattedRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Formatted',
    id: 'formatted',
    priority: 70,
    canRender: async (pastaItem: PastaItemDto) => {
        return (
            pastaItem.source.items.find((item) => item.type === 'text/html') !== undefined
        );
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {
        const data = pastaItem.source.items.find((item) => item.type === 'text/html');

        return (
            <>
                {data?.data && <div className="formatted-representation" dangerouslySetInnerHTML={{ __html: data.data }}></div>}
                {!data?.data && <div className="text-center formatted-representation">No data</div>}
            </>
        );
    }
}

export default formattedRepresentation;