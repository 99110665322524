import { ReactNode } from "react";

const TopNav = ({children}: { children: ReactNode }) => {


    return (
        <header className="top-nav flex items-center justify-between h-full">
            <div className="flex items-center h-full">
                <div className="top-nav__logo h-full mr-4">
                    <img className="h-full object-contain" src="/geo-logo.png" alt="logo" />
                </div>
                <h1 className="font-jost text-4xl font-bold p-2">Pasta Drawer</h1>
            </div>
            {children}
        </header>

    )
}

export default TopNav;
