import OpenAI from "openai";

class ChatGptService {

    private localStorageTokenKey = 'chat-gpt-token';

    private _client?: OpenAI;

    private get client(): OpenAI {
        if (!this._client) {

            // get token from local storage
            let token = localStorage.getItem(this.localStorageTokenKey);

            if (!token) {
                token = prompt('Please enter your OpenAI API key');
                if (token) {
                    localStorage.setItem(this.localStorageTokenKey, token);
                } else {
                    throw new Error('OpenAI API key is required');
                }
            }

            this._client = new OpenAI({
                apiKey: token!,
                dangerouslyAllowBrowser: true
            });
        }

        return this._client;

    }


    async completePromptWithVision(systemMessage: string, image: string): Promise<string> {
        const visionResponse = await this.client.chat.completions.create({
            model: 'gpt-4-vision-preview',
            max_tokens: 4096,
            messages: [
                {
                    role: "system",
                    content: systemMessage
                },
                {
                    role: "user",
                    content: [
                        {
                            type: "image_url",
                            image_url: {
                                url: image
                            }
                        }
                    ]
                }
            ]
        });

        // check for null or undefined and return empty string
        if (!visionResponse.choices || visionResponse.choices.length === 0 || !visionResponse.choices[0].message.content) {
            return '';
        }

        let result: string = visionResponse.choices[0].message.content.trim();

        // trim leading and trailing ``` if present
        if (result.startsWith('```')) {
            return result.substring(3, result.length - 3);
        } else {
            return result;
        }
    }

    async getMarkdownFromImage(imageFile: string): Promise<string> {

        const result = await this.client.chat.completions.create({
            model: 'gpt-4-vision-preview',
            max_tokens: 4096,
            messages: [
                {
                    role: "system",
                    content: `Your purpose in life is to convert hand written notes to markdown. Input will be an image_url and output will be markdown. Your response MUST be the raw markdown without formatting. Make sure there is an h1 (#) at the top of the markdown by either using a natural title or extracting based on the content.`
                },
                {
                    role: "user",
                    content: [
                        {
                            type: "image_url",
                            image_url: {
                                url: `${imageFile}`
                            }
                        }
                    ]
                }
            ]
        })

        // check for null or undefined and return empty string
        if (!result.choices || result.choices.length === 0 || !result.choices[0].message.content) {
            return '';
        }

        const resultText = result.choices[0].message.content.trim()

        // trim leading and trailing ``` if present
        if (resultText.startsWith('```')) {
            return resultText.substring(3, resultText.length - 3);
        } else {
            return resultText;
        }
    }
}

export const chatGptService = new ChatGptService();