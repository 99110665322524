import { jsonTryParse } from "../../helpers/json";
import { pastaItemGetPlainTextValue } from "../../helpers/pasta-item-data";
import { PastaItemDto } from "../../models/pasta.dtos"
import { Representation, representationCopyValueBase } from "../../models/representation.dto";

const prettyPrintJsonRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Pretty',
    id: 'pretty',
    priority: 100,
    canRender: async (pastaItem: PastaItemDto) => {
        const plainText = pastaItemGetPlainTextValue(pastaItem);

        if (!jsonTryParse(plainText)) {
            return false;
        }
        
        return true;
    },
    Template: (props: { pastaItem: PastaItemDto }) => {

        const json = jsonTryParse(pastaItemGetPlainTextValue(props.pastaItem));

        return (
            <pre className="pretty-representation p-4">
                {JSON.stringify(json, null, 2)}
            </pre>
        )
    }
}

export default prettyPrintJsonRepresentation;