import { imageScaleToContainInMaxWidthAndMaxHeight } from "../helpers/image";
import { PastaItemDto } from "../models/pasta.dtos";
import { Representation } from "../models/representation.dto";
import { Transformation } from "../models/transformation.dtos";
import { chatGptService } from "../modules/chat-gpt/chat-gpt.service";

const imageToMarkdownTransformation: Transformation = {
    name: "Image to Markdown",
    id: "image-to-markdown",
    canTransform: async (pastaItem: PastaItemDto) => {
        return pastaItem.source.items.some(i => i.type.indexOf('image') !== -1);
    },
    transform: async (pastaItem: PastaItemDto, representation: Representation) => {

        const imageData = pastaItem.source.items.find(i => i.type.indexOf('image') !== -1);

        // resize the image data
        const resizedData = await imageScaleToContainInMaxWidthAndMaxHeight(imageData?.data, 400, 400);

        try {
            const result = await chatGptService.completePromptWithVision(
                `Your purpose in life is to convert hand written notes to markdown. Input will be an image_url and output will be markdown. Your response MUST be the raw markdown without formatting. Make sure there is an h1 (#) at the top of the markdown by either using a natural title or extracting based on the content.`,
                resizedData
            );

            return result;
        } catch (error) {
            // log error to console
            console.log(error);
            throw new Error('Failed to convert image to markdown');    
        }

    }
}

export default imageToMarkdownTransformation;