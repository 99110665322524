import { PastaItemDto } from "../../models/pasta.dtos";
import { Representation, representationCopyValueBase } from "../../models/representation.dto";

const imageRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Image',
    id: 'image',
    priority: 50,
    canRender: async (pastaItem: PastaItemDto) => {
        // console.log('canrender')
        if (pastaItem.source.items.find(i => i.type.indexOf('image') !== -1)) {
            return true;
        }

        return false;
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {
        // console.log('render')
        const data = pastaItem.source.items.find(i => i.type.indexOf('image') !== -1);

        return (
            <div className="raw-representation">
                <img src={data?.data} alt="PastaDrawer img" />
            </div>
        )
    }
}

export default imageRepresentation;