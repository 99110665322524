import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { PastaRepository } from '../repository/pasta.repository';
import { PastaItemDto } from '../models/pasta.dtos';

interface PastaRepositoryContextData {
    pastaItems: PastaItemDto[];
    addItem: (item: PastaItemDto) => Promise<void>;
    deleteAll: () => Promise<void>;
    deleteItem: (item: PastaItemDto) => Promise<void>;
    updateItem: (item: PastaItemDto) => Promise<PastaItemDto>;
}

export const PastaRepositoryContext = React.createContext<PastaRepositoryContextData | undefined>(undefined);

export const PastaRepositoryProvider = ({ children }: { children: ReactNode }) => {
    const [pastaItems, setPastaItems] = useState<PastaItemDto[]>([]);
    const repo = useMemo(() => new PastaRepository(), []);

    useEffect(() => {
        // Load the initial data from the repository
        const fetchData = async () => {
            const items = await repo.getAll({ property: 'created', direction: 'desc' });
            setPastaItems(items);
        }

        fetchData();
    }, [repo]);  // Run this effect once on mount

    const addItem = async (item: PastaItemDto) => {
        await repo.create(item);
        // After adding the item, reload all items from the repo
        const items = await repo.getAll({ property: 'created', direction: 'desc' });
        setPastaItems(items);
    };

    const deleteAll = async () => {
        await repo.deleteAll();
        const items = await repo.getAll({ property: 'created', direction: 'desc' });
        setPastaItems(items);
        return;
    }

    const updateItem = async (item: PastaItemDto) => {
        return await repo.update(item);
    }

    const deleteItem = async (item: PastaItemDto) => {
        await repo.delete(item.id);
        const items = await repo.getAll({ property: 'created', direction: 'desc' });
        setPastaItems(items);
        return;
    }

    return (
        <PastaRepositoryContext.Provider value={{ pastaItems, addItem, deleteAll, updateItem, deleteItem }}>
            {children}
        </PastaRepositoryContext.Provider>
    );
};
