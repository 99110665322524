import { useContext, useEffect, useState } from "react";
import { PastaItemDto } from "../../models/pasta.dtos";
import { Representation, representationCopyValueBase } from "../../models/representation.dto";
import { pastaItemExtendData, pastaItemGetExtendedData } from "../../helpers/pasta-item-data";
import { PastaRepositoryContext } from "../../context/PastaRepositoryContext";

const linkPreviewRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Link Preview',
    id: 'link-preview',
    priority: 60,
    canRender: async (pastaItem: PastaItemDto) => {

        const cachedData = pastaItemGetExtendedData(pastaItem, linkPreviewRepresentation.id);

        if (cachedData) {
            return true;
        }

        const plainTextValue = pastaItem.source.items.find((item) => item.type === 'text/plain')?.data;

        if (plainTextValue) {
            // check if plainTextValue is in the shape of a url
            const urlRegex = new RegExp('^(https?:\\/\\/)' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

            if (urlRegex.test(plainTextValue)) {
                return true;
            }
        }

        return false;
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {

        const [linkPreview, setLinkPreview] = useState<LinkPreview | undefined>(undefined);
        const { updateItem } = useContext(PastaRepositoryContext)!;

        const cachedData = pastaItemGetExtendedData(pastaItem, linkPreviewRepresentation.id);
        const plainTextValue = pastaItem.source.items.find((item) => item.type === 'text/plain')?.data;

        // extract url from plainTextValue
        const urlRegex = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        const url = plainTextValue?.match(urlRegex)?.[0];

        useEffect(() => {
            if (cachedData) {
                setLinkPreview(cachedData);
            } else if (url) {
                unfurlUrl(url).then((linkPreview) => {
                    setLinkPreview(linkPreview);

                    pastaItemExtendData(pastaItem, linkPreviewRepresentation.id, linkPreview, updateItem);
                }).catch((error) => {
                    console.log(error);
                });
            }
        }, [cachedData, url, pastaItem, updateItem]);

        

        return (
            <div className="link-preview-representation p-4">
                {/* if */}
                {linkPreview && (
                    <>
                        <div className="flex space-x-4 bg-white shadow-md p-4 rounded-lg">
                            <div className="w-1/3">
                                <img className="rounded-lg" src={linkPreview.images[0]} alt="" />
                            </div>
                            <div className="w-2/3">
                                <div className="font-bold text-lg mb-2">
                                    {linkPreview.title}
                                </div>
                                <div className="text-gray-700 text-sm">
                                    {linkPreview.description}
                                </div>
                            </div>
                        </div>
                    </>

                )}
                {/* else */}
                {!linkPreview && (
                    <>Loading</>
                )}
            </div>
        );
    }
}

interface LinkPreview {
    url:         string;
    title:       string;
    siteName:    string;
    description: string;
    images:      string[];
    mediaType:   string;
    contentType: string;
    videos:      any[];
    favicons:    string[];
}

async function unfurlUrl(url: string): Promise<LinkPreview> {
    const previewServiceUrl = `${process.env.REACT_APP_LINK_PREVIEW_PATH}/preview?url=${url}`;
    const options: RequestInit = {
        method: 'GET'
    };

    try {
        const response = await fetch(previewServiceUrl!, options);
        const result = (await response.json());
        return result;
    } catch (err) {
        console.log('Could not get url preview');
        throw err;
    }
}

export default linkPreviewRepresentation;