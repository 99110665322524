import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ClipboardItemTransformedDto, PastaItemDto } from "../models/pasta.dtos";
import { RepresentationsContext } from "../context/RepresentationsContext";
import { asyncFilter } from "../helpers/array";
import { Representation, representationCopyValueBase } from "../models/representation.dto";
import { CheckIcon, TrashIcon, CubeTransparentIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import { PastaRepositoryContext } from "../context/PastaRepositoryContext";
import { TransformationsContext } from "../context/TransformationsContext";
import { Transformation } from "../models/transformation.dtos";
import { pastaItemExtendTransformedData, pastaItemGetTransformedData } from "../helpers/pasta-item-data";
import Dropdown from "./Dropdown";
import NavBar from "./NavBar";

function createRepresentationFromTransformedItem(transformedItem: ClipboardItemTransformedDto): Representation {
    return {
        ...representationCopyValueBase,
        id: transformedItem.id,
        name: transformedItem.name,
        priority: 51,
        canRender: async () => true,
        Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {

            const transformedData = pastaItemGetTransformedData(pastaItem, transformedItem.id);

            return (
                <div className="raw-representation p-4">
                    {transformedData.data}
                </div>
            )
        }
    } as Representation;
}

const PastaItem = ({ pasta, moveLeftSignal, moveRightSignal }: { pasta: PastaItemDto, moveLeftSignal?: number, moveRightSignal?: number }) => {

    const title = pasta.name;

    const { updateItem, deleteItem } = useContext(PastaRepositoryContext)!;
    const { representations } = useContext(RepresentationsContext);
    const { transformations } = useContext(TransformationsContext);
    const [selectedRepresentationId, setSelectedRepresentationId] = React.useState<string | undefined>(undefined);
    const [validRepresentations, setValidRepresentations] = useState<Representation[]>([]);

    const [validTransformations, setValidTransformations] = useState<Transformation[]>([]);

    const focusOnRepresentation = useCallback((index: number) => {
        const representation = validRepresentations[index];

        if (!representation) {
            return;
        }

        // if representation is hidden, unhide it
        if (representation.priority < 50) {
            representation.priority = 51;
        }

        setSelectedRepresentationId(representation.id);
    }, [validRepresentations]);

    useEffect(() => {
        if (moveLeftSignal) {

            // set previous representation as active
            const activeIndex = validRepresentations.findIndex((representation) => {
                return representation.id === selectedRepresentationId;
            });

            if (activeIndex > 0) {
                focusOnRepresentation(activeIndex - 1);
            } else {
                focusOnRepresentation(validRepresentations.length - 1);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveLeftSignal]);

    useEffect(() => {
        if (moveRightSignal) {
            // set next representation as active
            const activeIndex = validRepresentations.findIndex((representation) => {
                return representation.id === selectedRepresentationId;
            });

            if (activeIndex < validRepresentations.length - 1) {
                focusOnRepresentation(activeIndex + 1);
            } else {
                focusOnRepresentation(0);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moveRightSignal]);

    useEffect(() => {
        asyncFilter(representations, async (representation) => {
            return await representation.canRender(pasta);
        }).then((validRepresentations) => {

            const validRepresentationsFromTransforms: Representation[] = pasta.source.items.filter((item) => {
                return item.kind === 'transformed';
            }).map((item) => {

                const transformedItem = item as ClipboardItemTransformedDto;

                return createRepresentationFromTransformedItem(transformedItem);
            });

            setValidRepresentations([...validRepresentations, ...validRepresentationsFromTransforms]);

            if (validRepresentations.length > 0 && selectedRepresentationId === undefined) {
                setSelectedRepresentationId(validRepresentations[0].id);
            }
        });
    }, [representations, pasta, selectedRepresentationId]);

    useEffect(() => {
        asyncFilter(transformations, async (transformation) => {
            return await transformation.canTransform(pasta);
        }).then((validTransformations) => {
            setValidTransformations(validTransformations);
        });

    }, [transformations, pasta]);

    const h2Ref = useRef(null);

    const selectedRepresentation = validRepresentations.find((representation) => {
        return representation.id === selectedRepresentationId;
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const toggleEditHeader = () => {

        if (isEditing) {
            // save the new title
            // get value of h2
            const titleElement = h2Ref.current! as HTMLInputElement;
            const newTitle = titleElement.value;
            pasta.name = newTitle!;

            updateItem(pasta);
        }

        setIsEditing(!isEditing);

        if (!isEditing) {
            setTimeout(() => {
                // set focus on h2
                (h2Ref.current! as HTMLElement)?.focus();

                // select all text in h2
                document.execCommand('selectAll', false, undefined);
            }, 0);
        }
    };

    const onEnter = (event: any, fn: () => void) => {
        if (event?.key === 'Enter') {
            event.preventDefault();
            fn();
        }
    };



    const [showFullTemplate, setShowFullTemplate] = useState(false);

    const [contentOverflow, setContentOverflow] = useState(false);
    const templateRef = useRef(null);

    useEffect(() => {
        if (templateRef.current && (templateRef.current as any).scrollHeight > 200) {
            setContentOverflow(true);
        } else {
            setContentOverflow(false);
        }
    }, [selectedRepresentation]); // Re-run the effect when selectedRepresentation changes


    const handleTransformationClick = async (transformationId?: string) => {

        if (!transformationId) {
            return;
        }

        const transformation = validTransformations.find(x => x.id === transformationId);

        if (!transformation) {
            return;
        }

        // get current representation data
        const currentRepresentation = validRepresentations.find(x => x.id === selectedRepresentationId);

        if (!currentRepresentation) {
            // TODO toast
            return;
        }

        const transformResult = await transformation.transform(pasta, currentRepresentation);

        const transformIdentifier = `${transformation.id}-${currentRepresentation.id}`;
        const transformLabel = `${transformation.name} (${currentRepresentation.name})`;

        const itemResult = await pastaItemExtendTransformedData(pasta, transformLabel, transformIdentifier, transformResult, updateItem);

        let representation = validRepresentations.find(x => x.id === itemResult.id);

        if (!representation) {
            representation = createRepresentationFromTransformedItem(itemResult);
            setValidRepresentations(prevValidRepresentations => [...prevValidRepresentations, representation!]);
        }

        setSelectedRepresentationId(representation.id);
    }

    const onDoubleClick = () => {
        setShowFullTemplate(!showFullTemplate);
    }

    return (
        <div className="pasta-item mb-8" >
            <div className="pasta-item__layout flex flex-col shadow-md  bg-aqua">
                <div className="pasta-item__top-bar flex items-center mx-6 mt-4 mb-4">
                    {isEditing ? (
                        <>
                            <input ref={h2Ref} className="bg-transparent w-full pasta-item__title text-2xl font-jost" defaultValue={title} onKeyDown={(event) => onEnter(event, () => toggleEditHeader())}></input>
                        </>
                    ) : (
                        <>
                            <h2
                                tabIndex={0}
                                ref={h2Ref}
                                onKeyDown={(event) => onEnter(event, () => toggleEditHeader())}
                                onClick={() => toggleEditHeader()}
                                className="cursor-pointer pasta-item__title text-2xl flex-0 font-jost">{title}</h2>
                        </>
                    )}
                    <div className="pasta-item__actions justify-self-end flex-1 flex justify-end">
                        {isEditing ? (
                            <>
                                <button className="opacity-50 ml-2 hover:opacity-100 rounded-full bg-white p-2" onClick={() => toggleEditHeader()}>
                                    <CheckIcon className="h-5 w-5"></CheckIcon>
                                </button>
                            </>
                        ) : (<></>)}
                        <button
                            className=" ml-2 opacity-50 hover:opacity-100 rounded-full bg-white p-2"
                            onClick={() => deleteItem(pasta)}
                        >
                            <TrashIcon className="h-5 w-5"></TrashIcon>
                        </button>
                    </div>
                </div>
                <div className="pasta-item__representation_container flex flex-col bg-mint">
                    <div className="pasta-item__representation_header flex flex-row items-center mx-6 mt-4">

                        <NavBar
                            items={validRepresentations.map((representation, index) => ({
                                active: representation.id === selectedRepresentationId,
                                id: representation.id,
                                title: representation.name,
                                visible: representation.priority >= 50
                            }))}
                            onSelect={(representation) => focusOnRepresentation(validRepresentations.findIndex(x => x.id === representation.id))}

                        ></NavBar>
                        <div className="pasta-item__representation_actions flex-1 flex justify-end">
                            <Dropdown choices={validTransformations.map((transformation) => {
                                return {
                                    id: transformation.id,
                                    label: transformation.name
                                }
                            })} onItemSelected={(transformationId) => { handleTransformationClick(transformationId) }}>
                                <ChevronDownIcon className="opacity-0 group-hover:opacity-100 transition-opacity h-3 w-3 mr-1" />
                                <CubeTransparentIcon className="h-5 w-5" />
                            </Dropdown>
                        </div>
                    </div>
                    <div className="pasta-item__active-representation m-6 block relative" onDoubleClick={onDoubleClick}>
                        {
                            <>
                                {
                                    selectedRepresentation &&
                                    <div ref={templateRef} className={`representation-renderer border-dashed border-2 bg-white overflow-auto ${showFullTemplate || !contentOverflow ? '' : 'max-h-[200px] relative'}`}>
                                        <selectedRepresentation.Template pastaItem={pasta} />
                                        {!showFullTemplate && contentOverflow && <div className="absolute bottom-0 w-full h-16 "></div>}
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PastaItem;