import React, { useContext, useEffect } from 'react';
import './App.css';
import TopNav from './components/TopNav';
import PastaList from './components/PastaList';
import { PastaRepositoryContext } from './context/PastaRepositoryContext';
import { serializeClipboardEventData, serializeClipboardEventItems } from './helpers/clipboard';
import { ArrowDownOnSquareIcon, FireIcon } from '@heroicons/react/24/solid';

function App() {

  const { addItem, deleteAll } = useContext(PastaRepositoryContext)!;

  useEffect(() => {
    const pasteListener = async (e: ClipboardEvent) => {

      const clipboardData = await serializeClipboardEventData(e);

      // get friendly date string
      const date = new Date();
      const dateDisplay = date.toLocaleString();
      const id = date.toISOString();

      await addItem({
        created: date,
        modified: date,
        id: `pasta-${id}`,
        name: `Pasta from ${dateDisplay}`,
        source: {
          items: clipboardData.map(x => ({
            type: x.type,
            kind: x.kind,
            data: x.data
          }))
        }
      });
    };

    window.addEventListener('paste', pasteListener);

    // Make sure to clean up the event listener when the component is unmounted.
    return () => {
      window.removeEventListener('paste', pasteListener);
    };
  }, [addItem]);  // Dependency on repo ensures this runs when repo changes

  const pasteFromClipboard = async () => {
    const fromClipboard = await navigator.clipboard.read();

    // get friendly date string
    const date = new Date();
    const dateDisplay = date.toLocaleString();
    const id = date.toISOString();

    const clipboardData = await serializeClipboardEventItems(fromClipboard);

    await addItem({
      created: date,
      modified: date,
      id: `pasta-${id}`,
      name: `Pasta from ${dateDisplay}`,
      source: {
        items: clipboardData.map(x => ({
          type: x.type,
          kind: x.kind,
          data: x.data
        }))
      }
    });
  };

  const isMobile = window.innerWidth < 768;

  return (
    <div className="min-h-screen " style={{
      background: 'linear-gradient(135deg, #FAD0C4 10%, #FFD1FF 20%, #C1C6E4 40%, #B5E0E7 60%, #FEC8D8 80%, #DFE7FD 90%)',
      backgroundSize: 'cover'
    }}>
      <div className="App flex flex-col m-auto p-6 max-w-7xl ">
        <div className="top-section flex-none h-20 mb-8">
          <TopNav>
            <div className="justify-end flex">
              <button
                className={`bg-red-500 p-4 rounded  opacity-0 hover:opacity-100 transition-opacity focus:opacity-100`}
                onClick={() => deleteAll()}
              >
                <FireIcon className="h-5 w-5"></FireIcon>
              </button>
              <button
                className={`bg-blue-500 p-4 rounded  opacity-0 hover:opacity-100 transition-opacity focus:opacity-100 ml-2 ${isMobile ? 'opacity-100' : ''}`}
                onClick={() => pasteFromClipboard()}>
                <ArrowDownOnSquareIcon className="h-5 w-5"></ArrowDownOnSquareIcon>
              </button>
            </div>
          </TopNav>
        </div>
        <div className="bottom-section flex-grow">
          <PastaList />
        </div>
      </div>
    </div>

  );
}

export default App;
