import { PastaItemDto } from "./pasta.dtos";

export interface Representation {
    name: string;
    id: string;
    priority: number;

    canRender: (pastaItem: PastaItemDto) => Promise<boolean>;
    getValue: (pastaItem: PastaItemDto) => Promise<string>;
    Template: React.FunctionComponent<RepresentationProps>;
}

export const representationCopyValueBase: {
    getValue: (pastaItem: PastaItemDto) => Promise<string>;
} = {
    getValue: async (pastaItem: PastaItemDto) => {
        return pastaItem.source.items.find((item) => item.type === 'text/plain')?.data || '';
    },
}

export interface RepresentationProps {
    pastaItem: PastaItemDto;
}