import React, { ReactNode } from "react";
import { Transformation } from "../models/transformation.dtos";
import uppercaseTransformation from "../transformations/uppercase";
import imageToMarkdownTransformation from "../transformations/image-to-markdown";
import imageToDiagramTransformation from "../transformations/image-to-diagram";
import gptOcr from "../transformations/gpt-ocr";

interface TransformationsContextProps {
  transformations: Transformation[];
}

export const TransformationsContext = React.createContext<TransformationsContextProps>({ transformations: [] });

export const TransformationsProvider = ({ children }: { children: ReactNode }) => {
  
  // Collect all transformations in an array
  const transformations: Transformation[] = [
    uppercaseTransformation,
    imageToMarkdownTransformation,
    imageToDiagramTransformation,
    gptOcr,
  ];

  return (
    <TransformationsContext.Provider value={{ transformations }}>
      {children}
    </TransformationsContext.Provider>
  );
};