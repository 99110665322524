export function imageScaleToContainInMaxWidthAndMaxHeight(data: string, maxWidth: number, maxHeight: number) {
    return new Promise<string>(async (resolve, reject) => {

        // check if the image is already smaller than the max width and max height
        const { width, height } = await imageGetWidthAndHeight(data);

        if (width <= maxWidth && height <= maxHeight) {
            resolve(data);
            return;
        }

        const img = document.createElement('img');
        img.onload = () => {
            const width = img.width;
            const height = img.height;
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            const newWidth = width * ratio;
            const newHeight = height * ratio;

            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            resolve(canvas.toDataURL());
            
        }

        img.onerror = (err) => {
            reject(err);
        }

        img.src = data;
    });
}

export function imageGetWidthAndHeight(data: string): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        }

        img.onerror = (err) => {
            reject(err);
        }

        img.src = data;
    });
}