import { ClipboardItemDto, ClipboardItemTransformedDto, PastaItemDto } from "../models/pasta.dtos";


export function pastaItemGetPlainTextValue(item: PastaItemDto): string {
    return item.source.items.find(i => i.type === 'text/plain')?.data;
}

export function pastaItemGetHtmlValue(item: PastaItemDto) {
    return item.source.items.find(i => i.type === 'text/html')?.data;
}

export async function pastaItemExtendData(pastaItem: PastaItemDto, representationIdentifier: string, data: any, updateItemFn: (item: PastaItemDto) => Promise<PastaItemDto>) {

    const existingExtendedDataItem = pastaItemGetExtendedData(pastaItem, representationIdentifier);

    if (existingExtendedDataItem) {
        existingExtendedDataItem.data = data;

        for (let i = 0; i < pastaItem.source.items.length; i++) {
            const dataItem = pastaItem.source.items[i];
            if (dataItem.type === representationIdentifier) {
                pastaItem.source.items[i].data = existingExtendedDataItem.data;
                break;
            }
        }

    } else {
        pastaItem.source.items.push({
            type: representationIdentifier,
            kind: 'extended',
            data: data,
        });
    }

    return await updateItemFn(pastaItem);
}

export function pastaItemGetExtendedData(pastaItem: PastaItemDto, representationIdentifier: string) {
    return pastaItem.source.items.find(i => i.type === representationIdentifier)?.data;
}

export async function pastaItemExtendTransformedData(
    pastaItem: PastaItemDto,
    transformationLabel: string,
    transformationIdentifier: string,
    data: any, updateItemFn: (item: PastaItemDto) => Promise<PastaItemDto>): Promise<ClipboardItemTransformedDto> {

    const transformationId = pastaItem.id + '-' + transformationIdentifier;

    let result = pastaItemGetTransformedData(pastaItem, transformationId);

    if (result) {
        for (let i = 0; i < pastaItem.source.items.length; i++) {
            const dataItem = pastaItem.source.items[i];
            if (dataItem.id === transformationId) {
                pastaItem.source.items[i].data = data;
                break;
            }
        }
    } else {
        result = {
            type: transformationIdentifier,
            kind: 'transformed',
            data: data,

            // TODO this does not support multiple transformations of the same type
            id: transformationId,
            name: transformationLabel,
        }

        pastaItem.source.items.push(result);
    }

    await updateItemFn(pastaItem);

    console.log('result', result);

    return result as ClipboardItemTransformedDto;
}

export function pastaItemGetTransformedData(pastaItem: PastaItemDto, transformationIdentifier: string): ClipboardItemDto {
    return pastaItem.source.items.find(i => i.id === transformationIdentifier)!;
}