import { imageScaleToContainInMaxWidthAndMaxHeight } from "../helpers/image";
import { PastaItemDto } from "../models/pasta.dtos";
import { Representation } from "../models/representation.dto";
import { Transformation } from "../models/transformation.dtos";
import { chatGptService } from "../modules/chat-gpt/chat-gpt.service";

const gptOcr: Transformation = {
    name: "GPT OCR",
    id: "gpt-ocr",
    canTransform: async (pastaItem: PastaItemDto) => {
        return pastaItem.source.items.some(i => i.type.indexOf('image') !== -1);
    },
    transform: async (pastaItem: PastaItemDto, representation: Representation) => {

        const imageData = pastaItem.source.items.find(i => i.type.indexOf('image') !== -1);

        // resize the image data
        const resizedData = await imageScaleToContainInMaxWidthAndMaxHeight(imageData?.data, 400, 400);

        try {
            const result = await chatGptService.completePromptWithVision(
                `Your purpose in life is to extract text from an input image. Input will be an image_url and output will be the formatted text. Your response MUST be only the formatted text.`,
                resizedData
            );

            return result;
        } catch (error) {
            // log error to console
            console.log(error);
            throw new Error('Failed to extract text from image');    
        }

    }
}

export default gptOcr;