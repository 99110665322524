import { Representation, representationCopyValueBase } from "../../models/representation.dto";
import Editor from '@monaco-editor/react';

const codeEditorRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Code Editor',
    id: 'code-editor',
    priority: 30,
    canRender: async (pastaItem) => {

        // if plain text value, the return true
        if (pastaItem.source.items.find(i => i.type.indexOf('text/plain') !== -1)) {
            return true;
        }

        return false;
    },
    Template: ({ pastaItem }) => {

        const plainTextValue = pastaItem.source.items.find(i => i.type.indexOf('text/plain') !== -1);

        if (!plainTextValue?.data) {
            return (
                <div className="code-editor-representation">
                    <div className="text-center">No data</div>
                </div>
            )
        }

        return (
            <div className="code-editor-representation" style={{ height: '0', minHeight: '500px' }}>

                <Editor

                    theme="vs-dark"
                    defaultLanguage="javascript"
                    defaultValue={plainTextValue.data} />

            </div>
        )

    }
}

export default codeEditorRepresentation;