import { PastaItemDto } from "../models/pasta.dtos";
import { IDBPDatabase, openDB } from 'idb';

export class PastaRepository {
    private dbName = 'pasta-items';
    private storeName = 'pastas';
    private dbPromise: Promise<IDBPDatabase>;

    constructor() {
        const storeName = this.storeName;
        this.dbPromise = openDB(this.dbName, 2, {
            upgrade(db) {
                const store = db.createObjectStore(storeName, { keyPath: 'id' });
                store.createIndex('by_created', 'created');
            },
        });
    }

    public async get(id: string): Promise<PastaItemDto> {
        const db = await this.dbPromise;
        return db.get(this.storeName, id);
    }

    public async getAll(sort?: { property: 'created', direction: 'asc' | 'desc' }): Promise<PastaItemDto[]> {
        const db = await this.dbPromise;
        const tx = db.transaction(this.storeName);

        if (sort && sort.property === 'created') {
            const index = tx.store.index('by_created');
            const cursorDirection = sort.direction === 'asc' ? 'next' : 'prev';
            let cursor = await index.openCursor(undefined, cursorDirection);
            const items: PastaItemDto[] = [];

            while (cursor) {
                items.push(cursor.value);
                cursor = await cursor.continue();
            }

            return items;
        }

        return tx.store.getAll();
    }

    public async create(pasta: PastaItemDto): Promise<PastaItemDto> {

        const db = await this.dbPromise;
        const tx = db.transaction(this.storeName, 'readwrite');
        await tx.store.add(pasta);
        await tx.done;

        return pasta;
    }

    public async update(pasta: PastaItemDto): Promise<PastaItemDto> {
        const db = await this.dbPromise;
        const tx = db.transaction(this.storeName, 'readwrite');
        await tx.store.put(pasta);
        await tx.done;
        return pasta;
    }

    public async delete(id: string): Promise<void> {
        const db = await this.dbPromise;
        const tx = db.transaction(this.storeName, 'readwrite');
        await tx.store.delete(id);
        await tx.done;
    }

    public async deleteAll(): Promise<void> {
        const db = await this.dbPromise;
        const tx = db.transaction(this.storeName, 'readwrite');
        await tx.store.clear();
        await tx.done;
    }
}