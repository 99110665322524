import { useContext, useEffect } from "react";
import { pastaItemGetPlainTextValue, pastaItemGetExtendedData, pastaItemGetHtmlValue, pastaItemExtendData } from "../../helpers/pasta-item-data";
import { Representation, representationCopyValueBase } from "../../models/representation.dto";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { PastaRepositoryContext } from "../../context/PastaRepositoryContext";
import React from "react";
import "@blocknote/core/style.css";



const blockEditorRepresentation: Representation = {
    ...representationCopyValueBase,
    id: 'block-editor',
    name: 'Block Editor',
    priority: 50,
    canRender: async (pasta) => {
        const plainText = pastaItemGetPlainTextValue(pasta);
        return plainText != null;
    },
    Template: ({ pastaItem }) => {

        const { updateItem } = useContext(PastaRepositoryContext)!;

        const editor = useBlockNote({
            onEditorContentChange: (editor) => {

                // TODO is this performance intensive?
                pastaItemExtendData(pastaItem, 'block-editor', editor.topLevelBlocks, updateItem);
            }
        });

        useEffect(() => {
            
            const extendedData = pastaItemGetExtendedData(pastaItem, 'block-editor');

            if (extendedData) {
                editor.replaceBlocks(editor.topLevelBlocks, extendedData);
                return;
            }

            const html = pastaItemGetHtmlValue(pastaItem);

            if (html) {
                editor.HTMLToBlocks(html).then((contentBlocks) => {
                    editor.replaceBlocks(editor.topLevelBlocks, contentBlocks);
                    pastaItemExtendData(pastaItem, 'block-editor', contentBlocks, updateItem);
                });
                
            } else {
                const plainText = pastaItemGetPlainTextValue(pastaItem);

                editor.markdownToBlocks(plainText).then(contentBlocks => {
                    editor.replaceBlocks(editor.topLevelBlocks, contentBlocks);
                    pastaItemExtendData(pastaItem, 'block-editor', contentBlocks, updateItem);
                });
            }
        }, [editor, pastaItem, updateItem]);

        return <BlockNoteView editor={editor} />;
    }
};

export default blockEditorRepresentation;