import { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

interface NavItem {
    id: string;
    title: string;
    visible: boolean;
    active: boolean;
}

// NavBar component
const NavBar = ({ items, onSelect }: { items: NavItem[], onSelect?: (selectedItem: NavItem) => void }) => {

    const [localItems, setLocalItems] = useState<NavItem[]>([]); // Local state to manage items

    // Update localItems when items prop changes
    useEffect(() => {
        setLocalItems(items);
    }, [items]);
    
    useEffect(() => {
        const selectedItemId = localItems.find((item) => item.active)?.id;

        if (localItems.length > 0 && !selectedItemId) {
            setLocalItems(localItems.map((item, index) => {
                return {
                    ...item,
                    active: index === 0
                }
            }));

            return;
        }

        if (selectedItemId && !localItems.find((item) => item.id === selectedItemId)) {
            setLocalItems(localItems.map((item, index) => {
                return {
                    ...item,
                    active: index === 0
                }
            }));
            return;
        }

        const activeItem = localItems.find((item) => item.id === selectedItemId);
        if (activeItem && !activeItem.visible) {
            const activeItemIndex = localItems.findIndex((item) => item.id === selectedItemId);
            const newItems = [...localItems];
            newItems[activeItemIndex].visible = true;
            setLocalItems(newItems);
            return;
        }
    }, [localItems]);

    const visibleItems = localItems.filter((item) => item.visible);
    const hiddenItems = localItems.filter((item) => !item.visible);

    const clickHandler = (item: NavItem) => {
        const selectedItemId = localItems.find((item) => item.active)?.id;
        if (item.id === selectedItemId) return;

        if (onSelect) onSelect(item);
        
        setLocalItems(localItems.map((x) => {
            return {
                ...x,
                active: x.id === item.id
            }
        }));
    }

    const hiddenItemClickHandler = (item: NavItem) => {
        
        if (onSelect) onSelect(item);
        
        setLocalItems(localItems.map((x) => {
            return {
                ...x,
                active: x.id === item.id,
                visible: (x.id === item.id) ? true : x.visible
            }
        }));

    }

    return (
        <div className="flex flex-row">
            <ul className="nav-bar flex flex-row flex-0">
                {visibleItems.map((item, index) => {
                    const selectedItemId = localItems.find((item) => item.active)?.id;
                    const buttonBaseClass = 'inline-block underline-offset-8 p-2 px-4 font-jost rounded-sm';
                    const buttonClass = item.id === selectedItemId ?
                        `${buttonBaseClass} text-custom-green-dark bg-custom-coral shadow-coral text-white` :
                        `${buttonBaseClass} opacity-80 hover:opacity-80 hover:text-white hover:bg-custom-coral-dark hover:shadow-coral-dark border-solid text-gray-800`;

                    return (
                        <li key={index} className="nav-bar__item-option mr-2">
                            <button className={`${buttonClass} transition-all`} onClick={() => clickHandler(item)}>{item.title}</button>
                        </li>
                    )
                })}
            </ul>
            {(hiddenItems.length > 0) && (
                <div className="nav-bar__item-options flex flex-row flex-1">
                    <Dropdown choices={hiddenItems.map((item) => {
                        return {
                            id: item.id,
                            label: item.title
                        }
                    })} onItemSelected={(itemId) => { hiddenItemClickHandler(hiddenItems.find(x => x.id === itemId)!) }}>
                        <ChevronDownIcon className="h-5 w-5 mr-1" />
                    </Dropdown>
                </div>
            )}
        </div>
    );
};


export default NavBar;