export function jsonTryParse(input: string): any {

    if (!input) {
        return undefined;
    }

    try {
        return JSON.parse(input);
        // return input;
    } catch (e) { }

    // Step 1: Replace single quotes with double quotes
    let fixed = input.replace(/'/g, '"');

    // Step 2: Add double quotes around keys
    fixed = fixed.replace(/([{\s,])(\w+)(:)/g, '$1"$2"$3');

    // Step 3: Remove trailing commas before closing brackets or braces
    fixed = fixed.replace(/,(\s*[}\]])/g, '$1');

    // Attempt to parse and re-stringify to ensure valid JSON and format it properly
    try {
        return JSON.parse(fixed);
    } catch (error) {
        // console.error('Failed to parse the fixed JSON:', error);
        // return ''; // or return the original, or fixed string, depending on your use case
        return undefined;
    }
}
