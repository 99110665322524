import { PastaItemDto } from "../../models/pasta.dtos"
import { Representation } from "../../models/representation.dto";

const clipboardDataRepresentation: Representation = {
    name: 'Clipboard',
    id: 'clipboard-data',
    priority: 20,
    canRender: async (pastaItem: PastaItemDto) => {
        return true;
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {

        const value = JSON.stringify(pastaItem, null, 2);

        return (
            <div className="raw-representation p-4">
                <pre><code>{value}</code></pre>
            </div>
        )
    },
    getValue: async (pastaItem: PastaItemDto) => {
        return JSON.stringify(pastaItem, null, 2);
    }
}

export default clipboardDataRepresentation;