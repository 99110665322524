export async function serializeClipboardEventData(clipboardEvent: ClipboardEvent): Promise<{ type: string; kind: string; data: string; }[]> {
    const clipboardData = clipboardEvent.clipboardData;

    if (!clipboardData) {
        return [];
    }

    const serializedData: { type: string; kind: string; data: any }[] = [];

    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const type = item.type;

        if (item.kind === 'file') {
            // If the type starts with 'image/', it's an image data
            const blob = item.getAsFile();

            serializedData.push({
                type: type,
                kind: item.kind,
                data: getBase64DataFromBlobAsync(blob!)
            });
        } else {
            // For non-image data, use the regular getData method
            serializedData.push({
                type: type,
                kind: item.kind,
                data: getDataAsStringAsync(item)
            });

            item.getAsString(function (data) {
                serializedData.push({
                    type: type,
                    kind: item.kind,
                    data: data
                });
            });
        }
    }

    // foreach item in serializedData, if the data property is a promise, await it
    for (let index = 0; index < serializedData.length; index++) {
        const dataItem = serializedData[index];

        if (dataItem.data instanceof Promise) {
            dataItem.data = await dataItem.data;
        }
        
    }
    
    return serializedData;
}

export async function serializeClipboardEventItems(clipboardItems: ClipboardItems): Promise<{ type: string; kind: string; data: string; }[]> {

    if (!clipboardItems || clipboardItems.length === 0) {
        return [];
    }

    const serializedData: { type: string; kind: string; data: any }[] = [];

    const items = clipboardItems;
    for (let i = 0; i < items.length; i++) {
        const item = items[i];

        // iterate over types
        for (let j = 0; j < item.types.length; j++) {
            const type = item.types[j];

            const blob = await item.getType(type);

            if (type === "text/plain") {
                const text = await blob.text();
                // Process the text data

                serializedData.push({
                    type: type,
                    kind: 'string',
                    data: text
                });
            } else if (type === "text/html") {
                const html = await blob.text();
                // Process the text data

                serializedData.push({
                    type: type,
                    kind: 'string',
                    data: html
                });
            } else if (type.indexOf('image') !== -1) {
                // If the type starts with 'image/', it's an image data
                serializedData.push({
                    type: type,
                    kind: 'file',
                    data: getBase64DataFromBlobAsync(blob)
                });
            } else {
                
            }
            // Handle other data types (e.g., images) as needed
        }
    }

    // foreach item in serializedData, if the data property is a promise, await it
    for (let index = 0; index < serializedData.length; index++) {
        const dataItem = serializedData[index];

        if (dataItem.data instanceof Promise) {
            dataItem.data = await dataItem.data;
        }
        
    }
    
    return serializedData;
}

function getDataAsStringAsync(item: DataTransferItem): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        item.getAsString((data) => {
            resolve(data);
        });
    });
}

function getBase64DataFromBlobAsync(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        
        var reader = new FileReader();
        reader.onload = function (event: any) {
            resolve(event.target.result);
        };

        reader.readAsDataURL(blob as Blob);
    });
}
