import { PastaItemDto } from "../models/pasta.dtos";
import { Representation } from "../models/representation.dto";
import { Transformation } from "../models/transformation.dtos";

const uppercaseTransformation: Transformation = {
    name: "Uppercase",
    id: "uppercase",
    canTransform: async (pastaItem: PastaItemDto) => true,
    transform: async (pastaItem: PastaItemDto, representation: Representation) => {
        return (await representation.getValue(pastaItem)).toUpperCase();
    }
}

export default uppercaseTransformation;