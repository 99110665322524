import { useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';

export interface DropdownProps {
    choices: { id: string, label: string }[];
    onItemSelected: (id: string) => void;

    children: React.ReactNode;
}

const Dropdown = ({ choices, onItemSelected, children }: DropdownProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null); // Step 1: Create a ref for the dropdown

    // Toggle dropdown visibility
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Close dropdown if clicking outside of it
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {

            if (!event.target) return;

            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false); // Close dropdown
            }
        }

        // Add when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        // Remove event listener on cleanup
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [dropdownRef]); // Empty array ensures this effect only runs on mount and unmount

    const nodeRef = useRef(null);

    return (
        <div className="relative" ref={dropdownRef} onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <button type="button" className="group ml-2 p-2 inline-flex justify-center items-center w-full" onClick={toggleDropdown}>
                {/* render children */}
                {children}
            </button>
            <CSSTransition
                in={isOpen}
                timeout={200}
                classNames="dropdown"
                unmountOnExit
                appear
                nodeRef={nodeRef}
            >
                <ul
                    ref={nodeRef}
                    className={`absolute bg-white shadow-md shadow-gray-400 pt-2 z-10 right-0 whitespace-nowrap`}>
                    {choices.map((choice, index) => (
                        <li key={index} className="">
                            <button className="px-4 py-2 hover:bg-gray-100 w-full text-left" onClick={() => onItemSelected(choice.id)}>{choice.label}</button>
                        </li>
                    ))}
                </ul>

            </CSSTransition>
            
            {/* <div className="absolute bg-black opacity-25 top-0 left-0 w-full h-full" ></div> */}
        </div>
    );
}

export default Dropdown;