import { MutableRefObject, RefObject, createRef, useContext, useRef, KeyboardEvent, useState, useEffect } from "react";
import PastaItem from "./PastaItem";
import { PastaRepositoryContext } from "../context/PastaRepositoryContext";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { PastaItemDto } from "../models/pasta.dtos";


const PastaList = () => {

    const { pastaItems, deleteItem } = useContext(PastaRepositoryContext)!;
    const [leftKeySignals, setLeftKeySignals] = useState(pastaItems.map(() => 0));
    const [rightKeySignals, setRightKeySignals] = useState(pastaItems.map(() => 0));
    const refs: MutableRefObject<RefObject<HTMLLIElement>[]> = useRef<RefObject<HTMLLIElement>[]>([]);

    refs.current = pastaItems.map((_, index) => refs.current[index] = createRef());

    useEffect(() => {
        setLeftKeySignals(pastaItems.map(() => 0));
        setRightKeySignals(pastaItems.map(() => 0));
    }, [pastaItems]);

    const focusOnPastaItem = (index: number) => {
        refs.current[index].current?.focus();
    }
    
    const handlePastaKeyDown = (e: KeyboardEvent<HTMLElement>, pastaItem: PastaItemDto, index: number) => {

        if (e.target !== e.currentTarget) {
            return;
        }

        // on delete key press
        if (e.key === 'Delete') {
            deleteItem(pastaItem);

            // focus the next item
            if (index < pastaItems.length - 1) {
                focusOnPastaItem(index + 1);
            }

            // focus on previous if the last item was deleted
            if (pastaItems.length > 1 && index === pastaItems.length - 1) {
                focusOnPastaItem(index - 1);
            }
        }

        // on arrow down key press
        if (e.key === 'ArrowDown') {
            if (index < pastaItems.length - 1) {
                focusOnPastaItem(index + 1);
            }
        }

        // on arrow up key press
        if (e.key === 'ArrowUp') {
            if (index > 0) {
                focusOnPastaItem(index - 1);
            }
        }

        // on arrow left key press
        if (e.key === 'ArrowLeft') {
            setLeftKeySignals(leftKeySignals.map((value, i) => i === index ? value + 1 : value));
        }

        // on arrow right key press
        if (e.key === 'ArrowRight') {
            setRightKeySignals(rightKeySignals.map((value, i) => i === index ? value + 1 : value));
        }
    };


    return (
        <div className="pasta-list m-auto">
            <TransitionGroup component="ul" className="">
                {pastaItems.map((pastaItem, index) => {
                    
                    return (
                    <CSSTransition
                        nodeRef={refs.current[index]}
                        key={pastaItem.id}
                        timeout={250}
                        classNames="item"
                    >
                        <li
                            ref={refs.current[index]}
                            className="focus:ring-8 focus:outline-none focus:ring-aqua-dark ring-offset-mustard"
                            onKeyDown={(e) => handlePastaKeyDown(e, pastaItem, index)}
                            tabIndex={0}
                        > {/* You can remove mb-6 if it's not needed with the grid layout */}
                            <PastaItem pasta={pastaItem} moveLeftSignal={leftKeySignals[index]} moveRightSignal={rightKeySignals[index]} />
                        </li>
                    </CSSTransition>
                )})}
            </TransitionGroup>
        </div>
    )
};

export default PastaList;