import { pastaItemGetPlainTextValue } from "../../helpers/pasta-item-data";
import { PastaItemDto } from "../../models/pasta.dtos"
import { Representation, representationCopyValueBase } from "../../models/representation.dto";

const rawRepresentation: Representation = {
    ...representationCopyValueBase,
    name: 'Raw',
    id: 'raw',
    priority: 50,
    canRender: async (pastaItem: PastaItemDto) => {
        
        const plainText = pastaItemGetPlainTextValue(pastaItem);

        if (!plainText) {
            return false;
        }

        return true;
    },
    Template: ({ pastaItem }: { pastaItem: PastaItemDto }) => {

        return (
            <div className="raw-representation p-4">
                {pastaItemGetPlainTextValue(pastaItem)}
            </div>
        )
    }
}

export default rawRepresentation;